import uk from 'vuetify/lib/locale/uk'

export default {
  ...uk,
  locale: {
    switcherLabel: 'Виберіть мову'
  },
  main: {
    preview: {
      mainTitle: 'Онлайн консультації з професійним психологом',
      decorTextPart1: 'з будь-якого пристрою,',
      decorTextPart2: 'без встановлення додаткових програм',
      previewMainBtn: 'Вибрати психолога',
      additionText: 'єдина ціна 890грн за сесію ~ 50хв',
      dignityTitle1: '18 000+ годин',
      dignityTitle2: 'роботи з клієнтами',
      dignityTitle3: '90+ років',
      dignityAdditionText1: 'загальний досвід наших терапевтів',
      dignityAdditionText2: '100%',
      dignityAdditionText3: 'конфіденційність'
    },
    about: {
      sectionTitle: 'Консультації з психологом',
      sectionSubtitle: 'допоможуть покращити всі сфери вашого життя',
      cards: {
        card1: 'Позбавитись ПТСР, забути про тривожність і знайти гармонію в душі',
        card2: 'Вирівняти свій емоційний стан та впоратися зі стресом',
        card3: 'Підвищити власну самооцінку та прийняти себе',
        card4: 'Навчитися відстоювати власні кордони та говорити "Ні"',
        card5: 'Побудувати гармонійні стосунки з близькими людьми',
        card6: 'Знайти своє призначення, впоратися з прокрастинацією та вигорянням'
      }
    },
    check: {
      sectionTitle: 'Ми працюємо лише з професійними психологами',
      sectionSubtitle: 'суворо перевіряємо у спеціалістів',
      decorText: 'Не всі спеціалісти проходять наш відбір',
      button: 'Підібрати свого психолога',
      checkList: {
        itemTitle1: 'Освіту',
        itemDescription1: 'Лише вища психологічна та додаткова психотерапевтична',
        itemTitle2: 'Досвід роботи',
        itemDescription2: 'Понад 3 роки практики, наявність певної кількості годин супервізій та особистої терапії',
        itemTitle3: 'Співбесіда',
        itemDescription3: 'Кожен психолог проходить співбесіду для визначення рівня професіоналізму',
        itemTitle4: 'Підвищення кваліфікації',
        itemDescription4: 'Наші спеціалісти регулярно проходять семінари від провідних європейських спеціалістів'
      }
    },
    steps: {
      sectionTitle: 'Як працює наш сервіс',
      decorText: 'Якщо вам не підійшов жоден із запропонованих фахівців, ви можете скористатися ручним підбором',
      button: 'Підібрати психолога',
      stepsList: {
        itemTitle1: 'Заповніть анкету',
        itemDescription1: 'Вкажіть в анкеті контактні дані та позначте проблеми, ' +
          'з якими ви хотіли б розібратися. Також можете вказати побажання' +
          ' до фахівця, якщо вони у вас є.',
        itemTitle2: 'Виберіть психолога',
        itemDescription2: 'Сервіс запропонує вам на вибір 5-6 психотерапевтів, які працюють саме з вашими запитами',
        itemTitle3: 'Вкажіть час',
        itemDescription3: 'Забронюйте зручний час для консультації',
        itemTitle4: 'Почніть консультацію',
        itemDescription4: 'У призначений час зайдіть у свій кабінет на сайті для початку сесії. Додаткові програми встановлювати не потрібно.'
      }
    },
    usability: {
      mainTitle: 'Наш сервіс простий та зручний у використанні',
      sectionSubtitle: 'Ми робимо психотерапію зручною',
      button: 'Спробувати',
      usabilityList: {
        itemTitle1: 'Консультація 890грн',
        itemDescription1: 'Діє єдина ціна на консультації всіх спеціалістів. Ми зробили якісну психотерапію більш доступною',
        itemTitle2: 'Захищений вбудований відеочат',
        itemDescription2: 'Відеочат доступний у захищеному особистому кабінеті. Ви можете приєднатися з будь-якого пристрою. Дотримується повна конфіденційність',
        itemTitle3: 'Зручний особистий кабінет',
        itemDescription3: 'Ви легко можете керувати своїми консультаціями (призначати, переносити, скасовувати). Встановлюйте зручний для вас час'
      }
    },
    faq: {
      title: 'Питання-відповіді',
      questionWhy: 'Для чого потрібна консультації психолога?',
      answerWhy: 'Що б розібратися у своїй внутрішній реальності і структурувати свій досвід. Знайти вихід  із конфліктів, вирішити проблеми і досягти мети. Набути внутрішніх сил, щоб змінити себе і своє життя. Розкрити  свій потенціал і самореалізуватися в житті.',
      questionHow: 'Як працює психотерапія?',
      answerHow: 'В процесі психотерапії ви навчитеся краще розуміти свої реакції на інших і те, як ви ставитеся до себе. Завдяки психотерапії ви отримаєте новий досвід взаємовідносин, і зможете дізнатися, як поліпшити стосунки, у тому числі і стосунки з самим собою. Психотерапія дає дивовижні можливості розкривати свій внутрішній світ.',
      questionCriteria: 'За якими критеріями відбираються психотерапевти?',
      answerCriteria: 'У нас є ряд вимог до кандидатів: вища психологічна освіта, досвід консультування не менше 3 років і наявність рекомендацій від кого-небудь з визнаних членів професійного співтовариства. З кожним із терапевтів ми проводимо співбесіду. ',
      questionCost: 'Скільки це коштує?',
      answerCost: '890 гривень за 50-хвилинну сесію.',
      questionDuration: 'Чому сесія триває 50 хвилин?',
      answerDuration: 'Консультація з психологом - досить витратний, з точки зору емоцій, захід і продовжувати її більше, ніж 50 хвилин, може бути важко як для психотерапевта, так і для клієнта.',
      questionPayment: 'Як працює оплата? ',
      answerPayment: 'Ви прив\'язуєте свою картку при реєстрації. Гроші за сесію списуються за 12 годин до її початку.',
      questionPaymentDesc: 'Чому гроші списуються до надання послуги?',
      answerPaymentDesc: 'Сесія, яку клієнт пропускає, попереджаючи про це менш ніж за 12 годин, оплачується. У клієнтів неминуче виникає опір при наближенні до "секретів" його психіки. Клієнт починає пропускати сесії, придумуючи тисячі причин, що б не прийти на сесію. \n' +
        'Хорошої роботи не виходить. Тому, ми і внесли такий елемент дисципліни. Ми тільки за якісну і результативну психотерапію. \n',
      questionPlans: 'Що робити, якщо у мене змінилися плани після списання грошей?',
      answerPlans: 'На жаль, нічого - якщо ви попереджаєте про перенесення сесії пізніше, ніж за 12 годин до її початку, то гроші не повертаються.\n' +
        'Це правило ми запозичили у Фрейда.\n',
      questionShift: 'Як мені перенести сесію?',
      answerShift: 'У своєму особистому кабінеті. Ви вільно управляєте датою і часом.',
      questionTherapist: 'Мене не влаштував терапевт, що мені робити?',
      answerTherapist: 'Спробуйте попрацювати з іншим. У нас на Платформі усі терапевти високої кваліфікації. Але, є ще і особисте сприйняття людини. Ми це розуміємо, тому і пропонуємо на вибір декілька терапевтів.',
      questionFinish: 'Я хочу закінчити роботу з платформою - як мені це зробити?',
      answerFinish: 'Для цього просто треба відв\'язати свою карту у своєму особистому кабінеті',
      questionConfidential: 'Усі мої консультації з психологом конфіденційні?',
      answerConfidential: 'Так. Все що відбувається на сесії - тільки між вами і вашим психологом. Більше того, відеозв\'язок між вами і терапевтом здійснюється безпосередньо між вашими браузерами. Ні у кого немає можливості якось підглянути або підслухати вашу сесію.',
      haveQuestion: 'У мене залишилися питання - що мені робити?',
      inTouch: 'Ми на зв\'язку '
    },
    contacts: {
      title: 'Контакти',
      totalQuestions: 'Загальні питання і відділ турботи про клієнтів',
      corporateClients: 'Для корпоративних клієнтів',
      psychologistsPartnerships: 'Психологам з питань співпраці',
      advertising: 'Співпраця зі ЗМІ, спецпроєкти та реклама',
      mainTitle: 'Швидка психологічна допомога',
      chat: 'Чат на сайті',
      descriptionText: '"Платформа" не надає послуги швидкої психологічної допомоги.\n' +
        '          Якщо ваші проблеми потрібно вирішити терміново або вашому життю або здоров\'ю щось загрожує,\n' +
        '          будь ласка, зверніться до однієї з цих організацій:',
      contactsList: {
        itemDescription1: 'Гаряча лінія щодо попередження суїцидів та психологічної підтримки Lifeline Ukraine',
        itemDescription2: 'Гаряча лінія з питань запобігання домашньому насильству, торгівлі людьми та гендерній дискримінації',
        itemDescription3: 'Лінія допомоги військовим, жертвам насилля, усім, кому потрібна психологічна підтримка.',
        itemDescription4: 'Національна дитяча гаряча лінія'
      }
    },
    header: {
      menuItem1: 'Про проєкт',
      menuItem2: 'Наші психологи',
      menuItem3: 'Для психологів',
      menuItem4: 'Блог',
      menuItem5: 'Для бізнесу',
      // menuItem5: 'Про нас',
      EnterButton: 'Вхід'
    },
    footer: {
      question: 'Залишились питання?',
      contactUs: 'Ми завжди на зв\'язку',
      legal: {
        mainText:
          ' <b>Важливий момент.</b> "Платформа" — це не швидка психологічна\n' +
          '            допомога. Якщо у вас серйозні проблеми, що загрожують вашому життю,\n' +
          '            які вимагають негайного рішення, вам краще звернутися до\n' +
          '            будь-якої з цих організацій.',
        userAgreementPart1: 'Реєстрація на сайті означає згоду з',
        userAgreementLink: 'угодою користувача.',
        userAgreementPart2: 'Політика щодо обробки та захисту ',
        userAgreementLink2: 'персональних даних',
        terms:
          'При використанні будь-яких матеріалів цього сайту, посилання на джерело обов\'язкове.',
        protected: 'Всі права захищені!'
      },
      footerMenu: {
        FAQ: 'Питання-відповіді',
        Contacts: 'Контакти',
        workOffers: 'Робота в Платформа',
        blog: 'БЛОГ',
        ourPsychologists: 'Наші психологи',
        forBusiness: 'Платформа для бізнеса',
        aboutProject: 'Про проєкт'
      }
    }
  },
  view: {
    psychologists: {
      specialization: {
        // psychologist filters
        life_event_problems: 'Життєві ситуації',
        mental_health_condition_problems: 'Стан',
        relationship_problems: 'Відносини',
        career_problems: "Робота, кар'єра, навчання"
        /******************************************/
      }
    },
    psychDescrModal: {
      showAll: 'Показати повністю..',
      book: 'Забронювати',
      close: 'Закрити',
      experiense: 'Досвід роботи '
    },
    forPsychologists: {
      registrationButton1: 'Cпівпрацювати',
      mainTitle: 'Бажаєте стати частиною команди?',
      subtitle: 'Зосередьтесь виключно на своїй психологічній практиці, а ми\n' +
        '          візьмемо на себе організацію та просування ваших послуг',
      decorText: 'Працюйте коли вам зручно',
      requirements: {
        title: 'Ми чекаємо на справжніх професіоналів',
        subtitle: 'вимоги до психологів',
        listTitle: [
          'Освіта',
          'Досвід роботи',
          'Наявність ФОП'
        ],
        listDescription: [
          'Тільки вища психологічна. Наявність освіти в одному з методів (навчання тривалістю 300+ годин)',
          'Більше 3-х років практики, а також проходження супервізій та особистої терапії',
          'Ви повинні бути зареєстровані або мати можливість відкрити ФОП'
        ]
      },
      advantages: {
        title: 'Працюючи у Платформі ви отримаєте',
        listTitle: [
          'Клієнтів',
          'Свободу',
          'Ком\'юніті'
        ],
        listDescription: [
          'Вам не потрібно займатися своїм просуванням. Ми приводимо клієнтів готових до роботи',
          'Ви можете працювати з будь-якої точки світу і самі вирішуєте, коли та скільки клієнтів готові взяти',
          'Ви потрапляєте до спільноти професіоналів де можете обмінюватися знаннями та досвідом'
        ],
        button: 'Заповнити анкету',
        decorText: 'Ви займаєтеcь улюбленою роботою, решту ми беремо на себе'
      },
      start: {
        title: 'Як розпочати співпрацю',
        listTitle: [
          'Зареєструйтесь на платформі',
          'Пройдіть співбесіду',
          'Приступайте до роботи'
        ],
        listDescription: [
          'Заповніть анкету. Після відправки ми зв\'яжемось з вами та домовимось про співбесіду',
          'Співбесіда триває 30 хвилин і проходить у зручний для вас час',
          'Налаштовуйте особистий кабінет та приймайте перших клієнтів'
        ]
      },
      FAQ: 'Питання та відповіді',
      question1: 'Як довго розглядається анкета?',
      answer1: 'У середньому ми розглядаємо анкету один-два тижні',
      question2: 'Які етапи після розгляду анкети?',
      answer2: 'Якщо всі умови дотримані, то ми надсилаємо на ваш емейл запрошення на співбесіду. ' +
        'Співбесіду проводить один із наших кураторів (досвідчений психотерапевт, супервізор)',
      question3: 'Які умови ухвалення анкети на розгляд?',
      answer3: [
        '- Вища психологічна освіта',
        '- Підтверджена сертифікатами та дипломами освіта в одному з психотерапевтичних методів від 300+ годин',
        '- Особиста практика від 3-х років',
        '- Наявність регулярних супервізій',
        '- Досвід особистої психотерапії'
      ],
      question4: 'Скільки психотерапевт одержує за сесію на Платформі?',
      answer4: 'У середньому за одну сесію із клієнтом фахівець отримує близько 650 грн.'
    },
    blog: {
      header:
        'Мудра порада, цікава історія, список корисних лайфхаків чи наукова стаття. Хто знає, яке знання припаде вам до душі сьогодні?',
      subtitle1: 'Добре, що ми маємо вибір.',
      subtitle2:
        'Найкращі статті допоможуть знайти відповіді на непрості та вкрай важливі питання, відпочити душею та познайомитися з думкою експертів проєкту.',
      andMore: '...і ще багато цікавого',
      search: 'Пошук по статтям',
      categories: 'Категорії',
      title: 'Блог',
      subtitle: 'Цей розділ знаходиться в розробці.',
      localisationWarning: 'Стаття написана 1-ою мовою',
      posted: 'Опубліковано',
      close: 'Закрити',
      readMore: 'Читати статтю повністю',
      nextPost: 'Наступниа стаття',
      previousPost: 'Попереднія стаття'
    },
    business: {
      preview: {
        mainTitle: 'Психологічна підтримка ваших співробітників',
        subtitle: 'Допомагаємо в підвищенні ефективності трудової діяльності вашого колективу',
        decorTextPart: 'Cтворити сприятливий психологічний клімат в колективі',
        previewMainBtn: 'Залишити заявку',
        dignityTitle: 'Турбота про ментальне здоров`я працівників - це інвестиція',
        dignity1: 'зменшення плинності кадрів',
        dignity2: 'зменшення напруженості й конфліктності в колективі',
        dignity3: 'підвищення продуктивності праці колективу',
        dignity4: 'підвищення стресостійкості співробітників',
        formTitle: 'Залиште свої контактні дані, і ми зв\'яжемось з вами для обговорення деталей.',
        companyName: 'Назва компанії',
        employees: 'Кількість співробітників',
        phone: 'Телефон',
        contactChannel: 'Переважний спосіб зв\'язку',
        legalText: 'надаю згоду на ',
        legalSubtext: ' обробку персональних даних',
        loading: 'Загрузка'
      },
      solve: {
        mainTitle: 'Платформа - перевірений засіб вирішення багатьох проблем',
        secondTitle: 'Інвестуйте в психологічне здоров`я співробітників і вони будуть вражати вас результатами',
        decorText: 'Підтримуйте свою команду',
        list: {
          description1: 'Нестача мотивації працівників для виконання поставлених цілей',
          description2: 'Вигоряння та прокрастинація персоналу на робочому місті',
          description3: 'Недовіра і ділові претензії членів колективу один до одного',
          description4: 'Невисокий ступінь емоційного включення та взаємодопомоги',
          description5: 'Вплив особистих проблем працівника на продуктивність праці',
          description6: 'Відсутність усвідомлення і взяття відповідальності на себе за стан справ в колективі кожним із його членів'
        }
      },
      find: {
        title: 'Підберемо психолога,',
        subtitle: 'з яким співробітнику буде комфортно',
        btn: 'Дізнатися більше про сервіс',
        list: {
          description1: 'Співробітник заповнює анкету',
          description2: 'Алгоритм запропонує 6 фахівців які відповідають запиту',
          description3: 'Співробітник обирає психолога та час консультації'
        }
      },
      why: {
        title: 'Чому Платформа подобається співробітникам',
        list: {
          title1: 'Єдина ціна',
          description1: 'Діє єдина ціна на консультації всіх спеціалістів. Ми зробили якісну психотерапію більш доступною',
          title2: 'Захищений вбудований відеочат',
          description2: 'Відеочат доступний у захищеному особистому кабінеті. Можна приєднатися з будь-якого пристрою. Дотримується повна конфіденційність',
          title3: 'Зручний особистий кабінет',
          description3: 'Легко керувати своїми консультаціями (призначати, переносити, скасовувати). Встановлюйте зручний для вас час'
        }
      },
      selection: {
        title: 'Ми ретельно відбираємо психологів',
        subtitle: 'та працюємо з найкращими',
        decorText1: 'Співробітник який вирішив психологічні проблеми',
        decorText2: ' на 75% краще концентрується на своїх робочих задачах',
        btn: 'Підняти ефективність своїх співробітників',
        list: {
          title1: 'Перевірка освіти',
          description1: 'Наші спеціалісти мають виключно вищу психологічну та психотерапевтичну освіту. В цій сфері багато недоучок, і нам важливо їх відсіяти.',
          title2: 'Досвід роботи',
          description2: 'Понад три роки практики, наявність певної кількості годин супервізій та особистої терапії',
          title3: 'Особиста співбесіда',
          description3: 'Ретельно перевіряємо знання та навички кожного фахівця',
          title4: 'Регулярне навчання',
          description4: 'Ми проводимо супервізії та семінари для постійного розвитку наших психологів'
        }
      },
      comfortable: {
        title: 'З нами зручно працювати',
        list: {
          title1: 'Прозора звітність',
          description1: 'Детальна інформація про кількість проведених сесій для ваших співробітників',
          title2: 'Контроль за списком учасників',
          description2: 'Згенерований промокод дає змогу відстежувати, щоб тільки ваша команда мала доступ до сесій на Платформі',
          title3: 'Оплата лише за використання',
          description3: 'Ми беремо оплату лише за фактично проведені сесії для вашої команди',
          title4: 'Довільна модель компенсації',
          description4: 'Періодичні сесії за рахунок компанії, фіксований процент від кожної сесії, стовідсоткова компенсація - будь-яка схема прийнята у вашій компанії.'
        }
      },
      faq: {
        FAQ: 'Питання та відповіді',
        subtitle: 'Залишились питання - пишіть, ми завжди на звʼязку',
        question1: 'Чим Платформа краще постійного психолога в офісі?',
        answer1: 'Довіра співробітників до онлайн терапії значно вище, тому що вище рівень конфіденційності. ' +
          'По-перше, ні хто не знає хто із співробітників користується послугами психолога ' +
          '(з психологом в офісі це важко забезпечити), А по-друге - не виникає сумнівів в тому, ' +
          'що ніяка особиста інформація не потрапить до керівника',
        question2: 'Як донести до співробітників, що психотерапія це корисно?',
        answer2: 'Ми можемо надати вам під запит матеріали про користь сесій із психотерапевтом.',
        question3: 'Навіщо моїй компанії співпрацювати з Платформою?',
        answer3: 'Особисті проблеми співробітника будуть відображатися на його мотивованості та працездатності. Регулярні зустрічі з ' +
          'психологом допоможуть членам вашої команди налагодити те що давно їх турбує. Таким чином збільшується їх продуктивність' +
          ' на робочому місці. Співробітники дуже лояльні до компанії та керівників які допомагають їм в особистісному та професійному зростанні.',
        question4: 'Все, що розповідається на сесії, конфіденційно?',
        answer4: 'Все, що відбувається на сесії лише між співробітником та його психологом. Відеозв`язок здійснюється безпосередньо ' +
          'між пристроєм клієнта та пристроєм психолога, тому навіть не існує технічної можливості щось побачити чи почути.',
        question5: 'Як технічно працює Платформа для бізнесу?',
        answer5: 'Ми створюємо для вашої компанії промокод, який працівник вводить під час реєстрації. ' +
          'У промокоді закладені умови та обмеження щодо використання, які ви самі визначите.',
        question6: 'Співробітник може комусь передати свій промокод?',
        answer6: 'Ми обмежили використання промокоду лише у зв`язці з корпоративним e-mail.'
      },
      request: {
        title: 'Залишіть заявку, і ми вам запропонуємо найкращі варіанти, як посилити свою команду',
        btn: 'Залишити заявку'
      }
    },
    aboutUs: {
      heroTitle: 'Вітаємо вас на Платформі!',
      heroSubtitle: 'Якщо ви тут, то вже на крок наблизилися до внутрішнього спокою',
      forTitle: 'Ми створили цей сервіс для людей,',
      forDescription1: 'які хочуть урівноважити внутрішній стан та позбутися\n' +
        '            від психологічного тягаря. Ми не ворожимо на картах та не фотографуємо вашу ауру.',
      forDescription2: 'Все, що ми робимо для вас - підбираємо надійних та професійних фахівців\n' +
        '            із досвідом, які люблять свою роботу та несуть за неї відповідальність.',
      forText: '"Платформа" - це психотерапевти, які мають вищу психологічну освіту\n' +
        '          та не менше 3-х років консультативної практики. Тут немає пройдисвітів, які рвуться до професії,\n' +
        '        отримавши сертифікат після 2-х місячних курсів.',
      whyTitle: 'Чому так?',
      whySubtitle: 'Полегшено зітхайте, ви в безпеці!',
      whyText: 'Ми відчуваємо сьогоднішню потребу у якісній консультації, і саме тому створили сервіс,\n' +
        '        де професійну психологічну допомогу надають перевірені експерти. Вам нема про що хвилюватися.',
      whyArgument: 'аргументів, аби спробувати вже зараз:',
      whyList: {
        description1: 'Кожен спеціаліст проходить співбесіду із співзасновником сервісу, що має 12-річний досвід у сфері психології;',
        description2: 'Для індивідуальної консультації потрібен лише інтернет. Консультації відбуваються безпосередньо на платформі, ' +
          'вам не потрібно встановлювати додаткових програм;',
        description3: 'Фіксована та єдина ціна за консультацію;',
        description4: 'Оплата за сесію просто на платформі;',
        description5: 'Особистий підхід та гарантія результату.'
      },
      whyButton: 'Підібрати психолога'
    },
    auth: {
      psychologist: {
        Schedule: {
          title: 'Розклад',
          subtitle: 'Тут Ви можете вибрати дні, коли ви будете доступні для відображення пацієнтам.',
          description: 'Розклад відображається у форматі – поточний день + 6 днів.',
          btnSave: 'Зберегти',
          btnEdit: 'Оновити'
        }
      }
    }
  },
  pageNotFound: {
    Oops: 'Уупс..',
    description: 'Сторінка, яку Ви шукаєте, не існує. '
  }
}
