import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from '@/store'
import VueSwal from 'vue-swal'
import VueMeta from 'vue-meta'
import './styles-connect.sass'
import Vue2Editor from 'vue2-editor'
import vueDebounce from 'vue-debounce'
import VueFbCustomerChat from 'vue-fb-customer-chat'

Vue.config.productionTip = process.env.NODE_ENV !== 'production'
Vue.use(vueDebounce)
Vue.use(VueSwal)
Vue.use(VueMeta)
Vue.use(Vue2Editor)
Vue.use(VueFbCustomerChat, {
  page_id: process.env.VUE_APP_FB_ID, //  change 'null' to your Facebook Page ID,
  theme_color: '#00a4b7', // theme color in HEX
  locale: 'ru_RU'
})

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
